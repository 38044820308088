export const faveData = [
  {
    id: 1,
    title: "Favorite 1",
    description: "This is favorite item 1",
    user: { firstName: "Rahul", lastName: "Sharma", image: "" },
    course: { courseName: "HTML for Beginners" },
    review: "Great course! Helped me understand the basics very well.",
    rating: 4.5,
  },
  {
    id: 2,
    title: "Favorite 2",
    description: "This is favorite item 2",
    user: { firstName: "Sneha", lastName: "Patel", image: "" },
    course: { courseName: "JavaScript Fundamentals" },
    review: "Very helpful! The instructor explained everything clearly.",
    rating: 4.0,
  },
  {
    id: 3,
    title: "Favorite 3",
    description: "This is favorite item 3",
    user: { firstName: "Amit", lastName: "Kumar", image: "" },
    course: { courseName: "MERN Stack Development" },
    review: "Loved it! I feel more confident now.",
    rating: 5.0,
  },
  {
    id: 4,
    title: "Favorite 4",
    description: "This is favorite item 4",
    user: { firstName: "Priya", lastName: "Nair", image: "" },
    course: { courseName: "Advanced CSS Techniques" },
    review: "Good course but could use more examples.",
    rating: 3.5,
  },
  {
    id: 5,
    title: "Favorite 5",
    description: "This is favorite item 5",
    user: { firstName: "Vikram", lastName: "Singh", image: "" },
    course: { courseName: "Machine Learning with Python" },
    review: "Well structured and easy to follow.",
    rating: 4.8,
  },
  {
    id: 6,
    title: "Favorite 6",
    description: "This is favorite item 6",
    user: { firstName: "Anjali", lastName: "Mehta", image: "" },
    course: { courseName: "React Native Essentials" },
    review: "Fantastic course for building mobile apps!",
    rating: 4.7,
  },
  {
    id: 7,
    title: "Favorite 7",
    description: "This is favorite item 7",
    user: { firstName: "Rohan", lastName: "Desai", image: "" },
    course: { courseName: "Data Structures and Algorithms" },
    review: "Challenging but rewarding.",
    rating: 4.3,
  },
  {
    id: 8,
    title: "Favorite 8",
    description: "This is favorite item 8",
    user: { firstName: "Pooja", lastName: "Kapoor", image: "" },
    course: { courseName: "Docker and Kubernetes" },
    review: "Excellent content for containerization basics.",
    rating: 4.6,
  },
]
