export const NavbarLinks = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Notes",
    // path: '/catalog',
  },
  {
    title: "Catalog",
    // path: '/catalog',
  },

  // {
  //   title: "About Us",
  //   path: "/about",
  // },
  // {
  //   title: "Contact Us",
  //   path: "/contact",
  // },
  {
    title: "ResumeTemplate",
    path: "/resume/template",
  },
  {
    title: "Roadmaps",
    path: "/roadmaps/view",
  },
]
